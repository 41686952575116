import React from "react";
import { Admin } from "react-admin";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { authProvider } from "app/providers/auth";
import { dataProvider } from "data";
import { Resources } from "../resources";
import { theme } from "../theme";
import { Dashboard } from "./components/Dashboard";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { CustomLayout } from "./components/Layout";
import { LoginPage } from "./components/LoginPage";
import { i18nProvider } from "./providers/i18nProvider";

export function App() {
  return (
    <ErrorBoundary>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Admin
          title="STEEZY Admin"
          layout={CustomLayout}
          theme={theme}
          dataProvider={dataProvider}
          authProvider={authProvider}
          i18nProvider={i18nProvider}
          loginPage={LoginPage}
          dashboard={Dashboard}
        >
          {Resources}
        </Admin>
      </LocalizationProvider>
    </ErrorBoundary>
  );
}
