import React from "react";
import {
  Edit,
  EditProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useDataProvider,
  useNotify,
  useRecordContext,
  Button,
  useRefresh,
  SaveButton,
  useRedirect,
  Toolbar,
} from "react-admin";
import { useEnums } from "lib/hooks";
import { Box, BoxProps, Typography, Tooltip } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import { HelpInfo } from "lib/components/HelpInfo";
import { ChallengeStatus } from "types/graphql";
import { Badges } from "./components/Badges";
import { validateAchievement } from "../utils";
import { compileHelpInfo, AchievementHelpItem } from "../helpInfo";

const EDIT_HELP_ITEMS: AchievementHelpItem[] = [
  AchievementHelpItem.EDIT_TITLE,
  AchievementHelpItem.NAME,
  AchievementHelpItem.DESCRIPTION,
  AchievementHelpItem.TRIGGER_TYPE,
  AchievementHelpItem.CHALLENGE,
  AchievementHelpItem.BADGES,
];

// Temporary fix for the fullWidth prop not being accepted by the Box component even though it works
const StyledBox = (props: BoxProps & { fullWidth: boolean }) => (
  <Box {...props} />
);

const GoToChallengeButton = () => {
  const record = useRecordContext();
  if (!record?.challenge?.id) {
    return null;
  }

  return (
    <Button
      variant="text"
      component="a"
      href={`#/challenges/${record.challenge.id}/edit`}
      title="Go to Challenge"
    >
      <OpenInNewIcon />
    </Button>
  );
};

const RemoveChallengeButton = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();

  if (!record?.challenge) {
    return null;
  }

  const handleClick = async () => {
    // Add confirmation dialog
    if (!window.confirm("Are you sure you want to remove this challenge?")) {
      return;
    }

    try {
      await dataProvider.removeChallenge("achievements", { id: record.id });
      notify("Challenge removed successfully", "success");
      refresh();
    } catch (error) {
      notify("Error removing challenge", "error");
    }
  };

  const isApproved =
    record.challenge.challengeStatus === ChallengeStatus.Approved;
  const tooltipTitle = isApproved
    ? "This challenge cannot be removed because it is in an approved state. Set the challenge status to none or deleted to remove it from this achievement."
    : "Remove this challenge from the achievement";

  return (
    <Tooltip title={tooltipTitle} placement="top">
      <span>
        <Button
          onClick={handleClick}
          variant="text"
          color="secondary"
          disabled={isApproved}
        >
          <DeleteIcon />
        </Button>
      </span>
    </Tooltip>
  );
};

const AchievementEditToolbar = (props: any) => {
  const record = useRecordContext();
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const handleDelete = async () => {
    if (record.badges?.length > 0) {
      notify("Cannot delete achievement with existing badges", "error");
      return;
    }

    if (!window.confirm("Are you sure you want to delete this achievement?")) {
      return;
    }

    try {
      await dataProvider.delete("achievements", {
        id: record.id,
        previousData: record,
      });
      notify("Achievement deleted successfully", "success");
      redirect("/achievements");
    } catch (error) {
      notify("Error deleting achievement", "error");
    }
  };

  return (
    <Toolbar {...props} style={{ justifyContent: "space-between" }}>
      <SaveButton />
      <Button
        label="Delete Achievement"
        onClick={handleDelete}
        variant="text"
        color="secondary"
        startIcon={<DeleteIcon />}
      />
    </Toolbar>
  );
};

export function AchievementEdit(props: EditProps) {
  const { enums } = useEnums();

  return (
    <Edit {...props} mutationMode="pessimistic" actions={false}>
      <SimpleForm
        validate={validateAchievement}
        warnWhenUnsavedChanges
        toolbar={<AchievementEditToolbar />}
      >
        <Typography
          variant="h6"
          style={{ display: "flex", alignItems: "center" }}
        >
          Edit Achievement
          <HelpInfo helpInfos={compileHelpInfo(EDIT_HELP_ITEMS)} />
        </Typography>
        <TextInput
          source="name"
          validate={[required()]}
          style={{ width: "100%" }}
        />
        <TextInput source="description" multiline style={{ width: "100%" }} />
        <SelectInput
          source="triggerType"
          choices={enums?.achievementTriggerType?.map((type: string) => ({
            id: type,
            name: type,
          }))}
          validate={[required()]}
          style={{ width: "100%" }}
        />
        <StyledBox display="flex" alignItems="center" fullWidth>
          <ReferenceInput
            label="Challenge"
            source="challenge.id"
            reference="challenges"
            style={{ flex: 1 }}
            sort={{ field: "createdAt", order: "DESC" }}
            filter={{ challengeStatus: ["approved", "none"] }}
          >
            <SelectInput optionText="title" />
          </ReferenceInput>
          <GoToChallengeButton />
          <RemoveChallengeButton />
        </StyledBox>
        <Badges />
      </SimpleForm>
    </Edit>
  );
}
