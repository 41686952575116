import { gql } from "@apollo/client";
import { BADGE_FRAGMENT } from "data/badges/schema";

export const ACHIEVEMENT_FRAGMENT = gql`
  fragment AchievementFields on Achievement {
    id
    name
    description
    triggerType
    createdAt
    updatedAt
    badges {
      ...BadgeFields
    }
    challenge {
      id
      title
      challengeStatus
    }
  }
  ${BADGE_FRAGMENT}
`;

export const GET_LIST_ACHIEVEMENTS = gql`
  ${ACHIEVEMENT_FRAGMENT}

  query GetAchievements(
    $pagination: ConnectionArgs
    $sort: OrderByInput
    $filter: AchievementFilter
  ) {
    admin {
      achievements(pagination: $pagination, sort: $sort, filter: $filter) {
        totalCount
        edges {
          node {
            ...AchievementFields
          }
          cursor
        }
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const GET_ONE_ACHIEVEMENT = gql`
  query GetOneAchievement($id: String!) {
    admin {
      achievement: achievementById(id: $id) {
        ...AchievementFields
      }
    }
  }
  ${ACHIEVEMENT_FRAGMENT}
`;

export const GET_ONE_BADGE = gql`
  query GetOneBadge($id: String!) {
    badge: badgeById(id: $id) {
      ...BadgeFields
    }
  }
  ${BADGE_FRAGMENT}
`;

export const CREATE_ACHIEVEMENT = gql`
  mutation CreateAchievement($input: AchievementCreateInput!) {
    adminAchievementCreate(input: $input) {
      ...AchievementFields
    }
  }
  ${ACHIEVEMENT_FRAGMENT}
`;

export const UPDATE_ACHIEVEMENT = gql`
  mutation UpdateAchievement($input: AchievementUpdateInput!) {
    adminAchievementUpdate(input: $input) {
      ...AchievementFields
    }
  }
  ${ACHIEVEMENT_FRAGMENT}
`;

export const DELETE_ACHIEVEMENT = gql`
  mutation DeleteAchievement($id: String!) {
    adminAchievementDelete(id: $id)
  }
`;

export const REMOVE_ACHIEVEMENT_CHALLENGE = gql`
  mutation RemoveAchievementChallenge($achievementId: String!) {
    adminAchievementRemoveChallenge(achievementId: $achievementId) {
      ...AchievementFields
    }
  }
  ${ACHIEVEMENT_FRAGMENT}
`;

export const CREATE_BADGE = gql`
  mutation CreateBadge($input: BadgeCreateInput!) {
    adminBadgeCreate(input: $input) {
      ...BadgeFields
    }
  }
  ${BADGE_FRAGMENT}
`;

export const UPDATE_BADGE = gql`
  mutation UpdateBadge($input: BadgeUpdateInput!) {
    adminBadgeUpdate(input: $input) {
      ...BadgeFields
    }
  }
  ${BADGE_FRAGMENT}
`;

export const DELETE_BADGE = gql`
  mutation DeleteBadge($id: String!) {
    adminBadgeDelete(id: $id)
  }
`;

export const CREATE_BADGE_ASSET = gql`
  mutation CreateBadgeAsset($input: CreateAssetInput!) {
    createAsset(input: $input) {
      id
      signedUrl
    }
  }
`;
