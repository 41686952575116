import React from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { FieldTitle, useInput } from "ra-core";
import { Box, TextField } from "@mui/material";
import type { TextFieldProps } from "@mui/material";
import { isValid, set } from "date-fns";

interface DateTimeInputProps {
  label?: string;
  source: string;
  resource?: string;
  className?: string;
  isRequired?: boolean;
}

export function DateTimeInput(props: DateTimeInputProps): JSX.Element {
  const { label, source, resource = "", isRequired = false } = props;

  const { input } = useInput({ source });

  return (
    <Box sx={{ width: "100%" }}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker<Date>
          label={
            <FieldTitle
              label={label}
              source={source}
              resource={resource}
              isRequired={isRequired}
            />
          }
          value={input.value ? new Date(input.value) : null}
          onChange={(newValue: Date | null) => {
            if (!newValue) {
              input.onChange(null);
              return;
            }

            if (!isValid(newValue)) {
              return;
            }

            // If we have an existing value, preserve its date when only time changes
            const currentDate = input.value ? new Date(input.value) : null;
            if (currentDate && isValid(currentDate)) {
              // Check if only time was changed by comparing year/month/day
              if (
                currentDate.getFullYear() === newValue.getFullYear() &&
                currentDate.getMonth() === newValue.getMonth() &&
                currentDate.getDate() === newValue.getDate()
              ) {
                // Only update the time components
                const updatedDate = set(currentDate, {
                  hours: newValue.getHours(),
                  minutes: newValue.getMinutes(),
                  seconds: newValue.getSeconds(),
                });
                input.onChange(updatedDate.toISOString());
                return;
              }
            }

            // If no existing date or full date was changed, use the new value
            input.onChange(newValue.toISOString());
          }}
          renderInput={(params: TextFieldProps) => (
            <TextField {...params} margin="normal" />
          )}
        />
      </LocalizationProvider>
    </Box>
  );
}
