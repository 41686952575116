import { Typography, InputAdornment, Box } from "@material-ui/core";
import React from "react";
import { Create, SimpleForm, TextInput } from "react-admin";
import { HelpInfo } from "lib/components/HelpInfo";
import { DateTimeInput } from "lib/components/DateTime";
import SubjectIcon from "@material-ui/icons/Subject";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EmojiEventsIcon from "@material-ui/icons/EmojiEvents";
import { ChallengeStatus } from "types/graphql";
import { validateChallenge } from "./utils";
import { compileHelpInfo, ChallengeHelpItem } from "./helpInfo";

const CREATE_HELP_ITEMS: ChallengeHelpItem[] = [
  ChallengeHelpItem.CREATE_TITLE,
  ChallengeHelpItem.TITLE,
  ChallengeHelpItem.DESCRIPTION,
  ChallengeHelpItem.START_TIME,
  ChallengeHelpItem.END_TIME,
  ChallengeHelpItem.SUBTITLE_HEADER,
  ChallengeHelpItem.GOAL_HEADER,
  ChallengeHelpItem.EARNED_BY_HEADER,
];

export const ChallengeCreate = ({ ...props }) => (
  <Create {...props}>
    <SimpleForm
      validate={validateChallenge}
      initialValues={{ challengeStatus: ChallengeStatus.None }}
    >
      <Typography
        variant="h6"
        style={{ display: "flex", alignItems: "center" }}
      >
        Create Challenge
        <HelpInfo helpInfos={compileHelpInfo(CREATE_HELP_ITEMS)} />
      </Typography>
      {/* Comment out for now because we don't to allow this anymore/yet */}
      {/* <TextInput label="Class ID" source="forClass.id" fullWidth /> */}
      <TextInput source="title" fullWidth />
      <TextInput
        source="displayText.subtitleHeader"
        label="Subtitle Header"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SubjectIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="displayText.goalHeader"
        label="Goal Header"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <CheckCircleIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput
        source="displayText.earnedByHeader"
        label="Earned By Header"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmojiEventsIcon color="action" />
            </InputAdornment>
          ),
        }}
      />
      <TextInput source="description" fullWidth multiline />
      <Box
        style={{
          display: "flex",
          gap: 16,
          marginTop: 16,
          flex: 1,
          width: "500px",
        }}
      >
        <DateTimeInput label="Start Time" source="startTime" />
        <DateTimeInput label="End Time" source="endTime" />
      </Box>
    </SimpleForm>
  </Create>
);
